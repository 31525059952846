<template>
    <div>
        <div class="header">
            <img
                src="../../assets/images/jingyun_word.png"
                @click="$router.push({ path: '/index' })"
                alt=""
                class="logo_image"
            />
            <div class="works_name">
                {{ chapter_name }}
            </div>
            <div class="back_center" v-if="false">
                <el-button type="primary" @click="back_center" size="small"
                >返回个人中心
                </el-button>
            </div>
        </div>
        <div class="write_content">
            <el-row class="tac">
                <el-col :span="6">
                    <div class="menu_left">
                        <div class="top_title">
                            <el-button @click="dis_volume()">分卷管理</el-button>
                            <el-button
                                @click="set_new()"
                                v-if="volume_list && volume_list.length > 0"
                            >新建章节
                            </el-button>
                        </div>
                        <div class="main_menu_box">
                            <el-menu
                                ref="menu_ref"
                                @dragend="clearSelectStyle($event)"
                                :default-active="default_index"
                                class="el-menu-vertical-demo"
                            >
                                <el-submenu class="publish-article" index="1">
                                    <template slot="title">
                                        <span>{{ `已发布章节（${chapter_count}）` }}</span>
                                    </template>
                                    <el-submenu
                                        :index="`1-${index + 1}`"
                                        v-for="(item, index) in volume_list"
                                        :key="item.id"
                                    >
                                        <template slot="title">
                                            <div class="chapter_volume"
                                                 @dragover="volumeDragOverAction($event)"
                                                 @drop="volumeDropAction(item, volume_list)"
                                                 @dragleave="clearSelectStyle">
                                                <span>{{ item.chapter_num }}</span>
                                                <span>{{ item.chapter_name }}</span>
                                            </div>
                                        </template>
                                        <el-menu-item
                                            :index="`1-${index + 1}-${idx + 1}`"
                                            v-for="(it, idx) in item.section_info"
                                            :key="it.id"
                                            @click="get_content(it.id)"
                                        >
                                            <div
                                                :class="
                          'drag-item' + (select_page === it.id ? ' active' : '')
                        "
                                                draggable
                                                @dragstart="dragStartAction($event, it)"
                                                @dragover="dragOverAction($event, it)"
                                                @dragleave="clearSelectStyle"
                                                @drop="dropAction($event, it)"
                                            >
                        <span
                            class="page_status"
                            :class="[
                            it.status == 0 ? 'have_examine' : '',
                            it.status == 1 ? 'had_examine' : '',
                            it.status == 2 ? 'no_examine' : '',
                          ]"
                            v-if="it.status == 0 || it.status == -1"
                        >
                          {{
                                {
                                    "0": "待审核",
                                    "1": "已审核",
                                    "2": "无需审核",
                                    "-1": "不通过",
                                    "-2": "草稿箱",
                                }[it.status]
                            }}
                        </span>
                                                <span class="chapter_num">{{ it.title }}</span>
                                            </div>
                                        </el-menu-item>
                                    </el-submenu>
                                </el-submenu>
                                <el-submenu index="2">
                                    <template slot="title">
                                        <span>{{ `已保存章节（${chapter_two}）` }}</span>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item
                                            :index="`2-${index + 1}`"
                                            v-for="(item, index) in out_content"
                                            :key="index"
                                            @click="get_content(item.id)"
                                        >
                                            <div class="chapter_volume">
                                                <span>{{ item.title }}</span>
                                            </div>
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                            </el-menu>
                        </div>
                    </div>
                </el-col>
                <el-col :span="18" class="right_box">
                    <div class="wokes_text" v-loading="text_loading" v-show="!have_page">
                        <div class="top_title down_title">
              <span class="text_warning" v-if="status !== -2 && !have_page"
              >注：当前章节为已发布章节，系统不会自动保存和发布你所修改的内容，请做好文档备份</span
              >
                            <span></span>
                            <div class="edit_box" v-if="!have_page">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-if="status === -2 && text_word > 0"
                                    class="go_up"
                                    @click="lenthConfirm(false, 2)"
                                >发布
                                </el-button>
                                <div class="edit_btn">
                                    <!-- <i class="iconfont icon-zuojiahoutai-xiugai" @click="edit_btn_type=true" v-if="status!==0"></i> -->
                                    <div class="edit_btn_arr">
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            v-if="status !== -2 && text_word > 0"
                                            @click="lenthConfirm(false, 2)"
                                        >重新发布
                                        </el-button>
                                        <!-- <el-button type="primary" size="mini" v-if="status===-2"  @click="prv_content(false,2)">发布</el-button> -->
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            v-if="status === -2 && text_word"
                                            @click="prv_content(false)"
                                        >保存
                                        </el-button>
                                    </div>
                                </div>
                                <!--                                <div class="dis_edit">-->
                                <!--                                    <i-->
                                <!--                                            class="iconfont icon-shenglve"-->
                                <!--                                            @click="dis_edit_type=!dis_edit_type"-->
                                <!--                                    ></i>-->
                                <!--                                    <div-->
                                <!--                                            class="dis_edit_arr"-->
                                <!--                                            v-if="dis_edit_type"-->
                                <!--                                    >-->
                                <!--                                        <span @click="del_section()">删除本章</span>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                        <div class="title_row">
                            <!--                            <div class="works_num_box">第-->
                            <!--                                <el-input-->
                            <!--                                        v-model="chapter_content_list.page"-->
                            <!--                                        :disabled="have_page"-->
                            <!--                                ></el-input>-->
                            <!--                                章-->
                            <!--                            </div>-->
                            <textarea
                                :disabled="have_page"
                                v-model="chapter_content_list.title"
                                id="works_title"
                                rows="1"
                                placeholder="请输入标题，如第三章爱丽丝梦游仙境"
                                style=""
                            ></textarea>
                        </div>
                    </div>
                    <div
                        class="edictor_out"
                        v-loading="content_loading"
                        :disabled="have_page"
                        v-show="!have_page"
                    >
                        <div id="edictor_top" v-show="false"></div>
                        <div id="edictor" v-show="!have_page"></div>
                    </div>
                    <div class="no_notic" v-show="have_page">
                        <p>还没有章节 请先创建章节</p>
                        <el-button
                            type="primary"
                            size="samll"
                            @click="set_new()"
                            v-if="volume_list && volume_list.length > 0"
                        >去创建章节
                        </el-button>
                    </div>
                    <div class="word_num" v-if="!have_page">
                        <span>本章字节</span>
                        <span class="num">{{ text_word }}字</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-dialog
            title="分卷管理"
            :visible.sync="volume_type"
            @close="clear_volume()"
            width="70%"
        >
            <div class="volume_commod">
                <div class="volume_menu">
                    <div class="add_volume" @click="add_chapter()">新增分卷</div>
                    <div class="chapter_container" v-if="volume_list">
                        <div
                            class="volume_item"
                            v-for="(item, index) in volume_commed_list"
                            :key="index"
                            @click="hit_it(item)"
                            :class="item.id === volume_id ? 'choose_it' : ''"
                        >
<!--                            @dragstart="dragStartAction($event, item)"-->
<!--                            @dragover="dragOverAction($event, item)"-->
<!--                            @drop="dropAction($event, item)"-->
                            <div class="chapter_box">
                                <span>{{ item.chapter_num }}</span>
                                <span>{{ item.chapter_name }}</span>
                            </div>
                            <span>本卷共{{ item.section_count }}章节</span>
                        </div>
                    </div>
                </div>
                <div class="menu_right">
                    <div class="volume_num">{{ volume_content.chapter_num }}</div>
                    <div class="volume_name">
                        <span>分卷名称</span>
                        <el-input
                            v-model="volume_content.chapter_name"
                            placeholder="请输入(非必填)"
                        ></el-input>
                    </div>
                    <div class="btn_arr">
                        <!--                        <el-button-->
                        <!--                                @click="del_chapter()"-->
                        <!--                                v-if="do_chapter_type"-->
                        <!--                        >删除分卷-->
                        <!--                        </el-button>-->
                        <el-button
                            type="primary"
                            v-if="do_chapter_type"
                            @click="do_chapter(2)"
                        >保 存
                        </el-button>
                        <el-button
                            type="primary"
                            v-if="!do_chapter_type"
                            @click="do_chapter(1)"
                        >新 增
                        </el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            title="新增章节"
            :visible.sync="new_type"
            @close="clear_content()"
            width="50%"
        >
            <div class="from_dig">
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="ruleForm"
                >
                    <el-form-item label="章节名" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="卷数" prop="page">
                        <el-select
                            v-model="ruleForm.chapter_num"
                            :multiple="false"
                            placeholder="请选择添加到第几卷"
                        >
                            <el-option
                                v-for="(item, index) in volume_list"
                                :key="index"
                                :label="item.chapter_name"
                                :value="item.chapter_num"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="new_type = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sure_add('ruleForm')" size="small"
        >确 定</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
import E from "wangeditor";
import {
    write_chapter,
    works_content,
    chapter_manage,
    add_section,
    edit_content,
    del_content,
    edit_chapter,
    del_chapter_it,
    move_chapter,
    move_vol,
} from "@/https/write.js";
import {setInterval, clearInterval} from "timers";

export default {
    name: "write_center",
    components: {},
    data() {
        return {
            edit_btn_type: false,
            dis_edit_type: false,
            content_loading: false,
            do_chapter_type: false,
            text_loading: false,
            volume_type: false,
            new_type: false,
            books_id: this.$route.query.books_id,
            default_index: "1-1-1",
            section_id: null,
            editor: null,
            volume_id: null,
            out_content: [],
            status: "",
            chapter_count: "",
            chapter_two: "",
            chapter_content_list: {
                title: "",
                page: "",
            },
            volume_list: [
                {
                    id: "",
                    books_id: "",
                    page: "",
                    title: "",
                    status: 0,
                    words: "",
                    create_time: "",
                    chapter_num: "",
                },
            ],
            volume_commed_list: [{}],
            volume_content: {
                chapter_name: "",
                chapter_num: "",
            },
            max: "",
            ruleForm: {
                title: "",
                page: "",
            },
            rules: {
                title: [{required: true, message: "章节名不能为空", trigger: "blur"}],
                chapter_num: [
                    {required: true, message: "卷数不能为空", trigger: "blur"},
                ],
            },
            set_timePrv: null,
            content: "",
            have_page: true,
            select_page: 0,
            chapter_name: "",
            text_word: 0,
            edit_text: "",
            dragInfo: {
                dragItem: null,
            },
        };
    },
    created() {
    },
    mounted() {
        this.avoid_info();
        if (!this.editor) {
            this.init_edictor();
        }

        document.ondragend = function () {
            if (document.querySelectorAll(".item-before")) {
                document
                    .querySelectorAll(".item-before")
                    .forEach((item) => item.classList.remove("item-before"));
            }
            if (document.querySelectorAll(".item-after")) {
                document
                    .querySelectorAll(".item-after")
                    .forEach((item) => item.classList.remove("item-after"));
            }
        };
    },
    beforeDestroy() {
        this.clear_time();
    },
    methods: {
        handleSelect(val) {
            this.select_page = val;
        },
        bush_page() {
            // this.$router.go(0)
            window.location.reload();
        },
        // 初始富文本编辑器
        init_edictor() {
            let that = this;
            //富文本头部 和身体分离
            this.editor = new E("#edictor_top", "#edictor");
            // 关闭粘贴样式的过滤
            this.editor.customConfig = {
                uploadImgMaxLength: 0,
                // pasteFilterStyle:true,
            };
            this.editor.config.zIndex = 100;
            this.editor.config.onchange = (html) => {
                let arr = that.editor.txt
                    .text()
                    .replace(/　|\s+| /g, "")
                    .split("&nbsp;");
                let str = arr.join("");
                let arr2 = str.split(" ");
                let str2 = arr2.join("");
                that.text_word = str2.length || 0; //动态更新文章字数
            };
            this.editor.config.pasteFilterStyle = true;
            // 自定义处理粘贴的文本内容
            this.editor.config.pasteTextHandle = function (content) {
                let str = content;

                str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
                str = str.replace(/<style>[\s\S]*?<\/style>/gi, "");
                str = str.replace(/　|\s+| /g, ""); //去除空格

                //将所有换行的标致全部替换为|-|  然后去除所有html标签后 |-|替换为<br/>
                str = str.replace(/<br \/>/gi, "|-|");
                str = str.replace(/<br\/>/gi, "|-|");
                str = str.replace(/<br>/gi, "|-|");
                str = str.replace(/\r\n/gi, "|-|");
                str = str.replace(/\n/gi, "|-|");
                str = str.replace(/<\/p>/gi, "|-|");

                str = str.replace(/<\/?[^>]*>/g, ""); //去除所有的标签
                let arrays = str.split("|-|");
                arrays = arrays.filter((item) => item);
                return arrays.map((item) => `<p>${item}</p>`).join("");
            };

            this.editor.create();
        },
        //获取书籍相关信息
        avoid_info(sectionId, onlyRefreshList) {
            let props = {
                books_id: this.$route.query.books_id,
            };
            write_chapter(props).then((res) => {
                this.volume_list = res.data.data;
                if (onlyRefreshList) return;
                this.chapter_name = res.data.name;
                this.chapter_count = res.data.chapter_count;
                this.chapter_two = res.data.outline.length;
                this.out_content = res.data.outline;
                let section = {};
                if (sectionId) {
                    section = {
                        id: sectionId,
                    };
                } else {
                    section =
                        this.volume_list &&
                        this.volume_list.length &&
                        this.volume_list[0].section_info &&
                        this.volume_list[0].section_info.length
                            ? this.volume_list[0].section_info[0]
                            : this.out_content && this.out_content.length
                            ? this.out_content[0]
                            : null;
                }
                if (section) {
                    this.get_content(section.id);
                    this.have_page = false;
                } else {
                    this.have_page = true;
                }
            });
        },
        //获取章节内容
        get_content(id) {
            if (this.set_timePrv) {
                this.clear_time();
            }
            this.text_word = 0;
            let props = {
                section_id: id,
            };
            if (this.editor.txt.html()) {
                this.editor.txt.html(" ");
            }
            if (id) {
                this.have_page = false;
                this.content_loading = true;
                works_content(props)
                    .then((res) => {
                        if (res.code === 0) {
                            this.select_page = res.data && res.data.books_section_id;
                            this.chapter_content_list = res.data;
                            this.section_id = this.chapter_content_list.books_section_id;
                            this.editor.txt.html(this.chapter_content_list.content);
                            this.status = res.data && res.data.status;
                            if (res.data.status === -2) {
                                this.time_init();
                            }
                        }
                        this.mix_word();
                    })
                    .finally(() => (this.content_loading = false));
            }
        },
        //统计字数
        mix_word() {
            if (this.editor) {
                let arr = this.editor.txt.text().split("&nbsp;");
                let str = arr.join("");
                let arr2 = str.split(" ");
                let str2 = arr2.join("");
                this.text_word = str2.length || 0; //动态更新文章字数
            }
        },
        //分卷管理
        dis_volume() {
            this.volume_type = true;
            let props = {
                books_id: this.books_id,
            };
            chapter_manage(props).then((res) => {
                if (res.code === 0) {
                    this.volume_commed_list = res.data.chapter_data;
                    if (this.volume_commed_list && this.volume_commed_list.length) {
                        this.volume_id = this.volume_commed_list[0].id;
                        this.volume_content = this.volume_commed_list[0];
                        this.do_chapter_type = true;
                    }
                    this.max = res.data.max;
                }
            });
        },
        hit_it(row) {
            this.editor.txt.clear();
            this.volume_id = row.id || "";
            this.volume_content = row;
            if (this.volume_id) {
                this.do_chapter_type = true;
            } else {
                this.do_chapter_type = false;
            }
        },
        //清除 添加分卷弹框缓存
        clear_volume() {
            this.volume_commed_list = [];
            this.volume_content = "";
            this.max = "";
        },
        //点击新建章节
        set_new() {
            this.new_type = true;
        },
        //确认添加章节
        sure_add(formName) {
            let props = {
                title: this.ruleForm.title,
                page: this.ruleForm.page,
                chapter_num: this.ruleForm.chapter_num,
                books_id: this.$route.query.books_id,
            };
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    add_section(props).then((res) => {
                        if (res.code === 0) {
                            this.$message.success("新建成功");
                            this.new_type = false;
                            this.avoid_info(res.data && res.data.section_id);
                        } else {
                            this.$message.warning(res.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        lenthConfirm(type, num) {
            if (this.text_word < 2000) {
                this.$confirm(
                    "本章节不满2000字，将不计入全勤考勤系统的有效章节和有效字数，确认发布本章节吗？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(() => {
                    this.prv_content(type, num);
                });
            } else {
                this.prv_content(type, num);
            }
        },

        //保存章节内容
        prv_content(type, num) {
            if (this.chapter_content_list.books_section_id) {
                this.have_page = false;
            }
            let chapter_status = num ? 2 : 1;

            var txt = this.editor.txt.html();
            if (txt) {
                var reg1 = new RegExp(
                    '<p><span style="text-indent: 2em;"><br></span></p>',
                    "g"
                );
                txt = txt.replace(reg1, "");
                txt = "<p>" + txt;

                reg1 = new RegExp("<p><p>", "g");
                txt = txt.replace(reg1, "<p>");
                console.log(111);

                reg1 = new RegExp("<p><br/></p>", "g");
                txt = txt.replace(reg1, "");

                reg1 = new RegExp("<br>", "g");
                txt = txt.replace(reg1, "</p><p>");

                reg1 = new RegExp("</br>", "g");
                txt = txt.replace(reg1, "");

                reg1 = new RegExp("</o:p>", "g");
                txt = txt.replace(reg1, "");

                reg1 = new RegExp("<o:p>", "g");
                txt = txt.replace(reg1, "");

                reg1 = new RegExp("<div></div>", "g");
                txt = txt.replace(reg1, "");

                reg1 = new RegExp("<div>", "g");
                txt = txt.replace(reg1, "<p>");

                reg1 = new RegExp("</div>", "g");
                txt = txt.replace(reg1, "</p>");

                reg1 = new RegExp("<p></p>", "g");
                txt = txt.replace(reg1, "");

                //去掉空格
                txt = txt.replace(/\s*/g, "");

                var reg2 = new RegExp('<spanstyle="text-indent:2em;">', "g");
                txt = txt.replace(reg2, "");

                var reg3 = new RegExp('</spanstyle="text-indent:2em;">', "g");
                txt = txt.replace(reg3, "");
                console.log(txt);
            }

            this.editor.txt.html(txt);

            console.log(this.editor.txt.html());

            let props = {
                content: this.editor.txt.html() || "",
                status: chapter_status,
                section_id: this.chapter_content_list.books_section_id,
                title: this.chapter_content_list.title,
            };
            if (type) {
                if (!this.editor.txt.html() && type) {
                    return;
                }
                if (props.content && props.section_id && props.title) {
                    edit_content(props).then((res) => {
                        if (res.code === 0) {
                            this.have_page = false;
                        } else {
                            this.$message.warning(res.msg);
                        }
                    });
                }
            } else {
                if (!this.editor.txt.html()) {
                    this.$message.warning("章节内容为空,无需保存哦~");
                    return;
                }
                edit_content(props).then((res) => {
                    if (res.code === 0) {
                        if (num !== 2) {
                            this.$message.success("保存成功");
                        } else {
                            this.$message.success("发布成功");
                        }
                        this.avoid_info(this.chapter_content_list.books_section_id);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            }
        },
        //清空新建章节缓存
        clear_content() {
            this.$refs.ruleForm.resetFields();
        },
        // 初始化定时器 定时缓存草稿
        time_init() {
            let that = this;
            this.set_timePrv = setInterval(() => {
                that.prv_content(true);
            }, 60000);
        },
        //清除定时器
        clear_time() {
            clearInterval(this.set_timePrv);
            this.set_timePrv = null;
        },
        // 删除章节
        del_section() {
            let props = {
                section_id: this.chapter_content_list.books_section_id,
            };
            if (props.section_id) {
                del_content(props).then((res) => {
                    if (res.code === 0) {
                        this.section_id = "";
                        this.dis_edit_type = false;
                        this.$message.success("删除成功");
                        if (this.set_timePrv) {
                            this.clear_time();
                        }
                        // this.$router.go(0)
                        window.location.reload();
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            } else {
                this.$message.warning("请先选择章节");
            }
        },
        //新增/编辑分卷
        do_chapter(num) {
            let props = {
                type: num,
                chapter_name: this.volume_content.chapter_name,
                books_id: this.books_id,
            };
            if (num === 2) {
                props.chapter_id = this.volume_content.id;
            }
            edit_chapter(props).then((res) => {
                if (res.code === 0) {
                    this.$message.success("success");
                    this.avoid_info();
                    this.volume_type = false;
                    // this.$router.go(0)
                    window.location.reload();
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        //新增分卷前端样式数据模拟添加
        add_chapter() {
            let num;
            num = this.volume_commed_list.length || 0;
            if (num && this.max === this.volume_commed_list[num - 1].chapter_num) {
                this.$message.warning("一次只能新增一卷");
            } else {
                let arr = {
                    chapter_num: this.max,
                    chapter_name: "",
                    count: 0,
                };
                this.volume_commed_list.push(arr);
                this.hit_it(this.volume_commed_list[num]);
            }
        },
        //删除分卷
        del_chapter() {
            let props = {
                chapter_id: this.volume_content.id,
            };
            del_chapter_it(props).then((res) => {
                if (res.code === 0) {
                    this.$message.success("删除成功");
                    this.dis_volume();
                    this.avoid_info();
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        back_center() {
            let params = {
                link: "/author_center",
                name: "作家中心",
                id: 3,
                icon: "icon-ren",
            };
            sessionStorage.setItem("mine_menu", JSON.stringify(params));
            this.$router.push({
                path: "/author_center",
            });
        },
        dragStartAction(e, chapter) {
            this.dragInfo.dragItem = chapter;
        },
        volumeDragOverAction(e){
            e.target.classList.add('chapter_over_class');
        },
        dragOverAction(e, targetItem) {
            const {dragItem} = this.dragInfo;
            let key = dragItem.chapter_name ? "chapter_num" : "page";
            if (dragItem[key] === targetItem[key]) return;
            if (key === "chapter_num" && !e.target.className.includes("volume_item"))
                return;
            this.clearSelectStyle();
            if (dragItem[key] > targetItem[key]) {
                e.target.classList.add("item-before");
            } else {
                e.target.classList.add("item-after");
            }
        },
        clearSelectStyle() {
            if (document.querySelectorAll(".item-before")) {
                document
                    .querySelectorAll(".item-before")
                    .forEach((item) => item.classList.remove("item-before"));
            }
            if (document.querySelectorAll(".item-after")) {
                document
                    .querySelectorAll(".item-after")
                    .forEach((item) => item.classList.remove("item-after"));
            }
            if (document.querySelectorAll(".chapter_over_class")) {
                document
                    .querySelectorAll(".chapter_over_class")
                    .forEach((item) => item.classList.remove("chapter_over_class"));
            }
        },
        volumeDropAction(volume, list){
            console.log(list);
            const {dragItem} = this.dragInfo;
            let section_info = [];
            (list || []).forEach(item=>{
                section_info = section_info.concat(item.section_info || []);
            });
            let page = 0;
            let i = section_info.findIndex(it=>it.chapter_num === volume.chapter_num);
            if(i === -1){
                let index = [...section_info].reverse().findIndex(it=>it.chapter_num === (volume.chapter_num - 1));
                if(index === -1){
                    page = 1;
                }else{
                    index = section_info.length - 1 - index;
                    page = section_info[index] && section_info[index].page;
                }
            }else if(i>0){
                page = section_info[i-1].page
            }else{
                page = section_info[0].page
            }
            this.moveRequest({
                current_page: dragItem.page,
                to_page: page,
                change_chapter_num: volume.chapter_num,
                book_id: this.$route.query.books_id,
            })
            this.clearSelectStyle();
        },
        dropAction(e, targetItem) {
            const {dragItem} = this.dragInfo;
            if (!dragItem.chapter_name && dragItem.page === targetItem.page) return;
            if (
                dragItem.chapter_name &&
                dragItem.chapter_num === targetItem.chapter_num
            )
                return;
            if (
                dragItem.chapter_name &&
                !e.currentTarget.className.includes("volume_item")
            )
                return;
            e.currentTarget.classList.remove("item-before");
            e.currentTarget.classList.remove("item-after");
            // if (
            //     !dragItem.chapter_name &&
            //     dragItem.chapter_num !== targetItem.chapter_num
            // ) {
            //     this.$message.warning("章节不能跨卷移动");
            //     return;
            // }
            if (dragItem.chapter_name) {
                move_vol({
                    current_chapter: dragItem.chapter_num,
                    to_chapter: targetItem.chapter_num,
                    book_id: this.$route.query.books_id,
                }).then((res) => {
                    if (res) {
                        this.$message.success("移动卷成功!");
                        this.dis_volume();
                        this.avoid_info(undefined, true);
                    }
                });
                this.dragInfo.dragItem = null;
                return;
            }
            this.moveRequest({
                current_page: dragItem.page,
                to_page: targetItem.page,
                change_chapter_num: dragItem.chapter_num === targetItem.chapter_num ? undefined : targetItem.chapter_num,
                book_id: this.$route.query.books_id,
            })
            this.dragInfo.dragItem = null;
        },
        moveRequest(data){
            move_chapter(data).then((res) => {
                if (res) {
                    this.$message.success("移动章节成功!");
                    this.avoid_info(undefined, true);
                }
            });
        }
    },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.header {
    @include flex-row();
    padding: 10px;
    width: 100%;
    position: relative;

    .logo_image {
        width: 98px;
        height: 50px;
        margin-right: 30px;
        cursor: pointer;
    }

    .works_name {
        font-size: 18px;
        line-height: 50px;
        height: 50px;
        font-weight: bold;
        padding: 0 30px;
        border-left: 1px solid #515a6e;
    }

    .back_center {
        position: absolute;
        right: 0;
        margin-right: 15px;
    }
}

.write_content {
    @include flex-row-between();
    width: 100%;
    height: calc(100vh - 71px);
    overflow-y: hidden;

    .tac {
        width: 100%;
        height: 100%;

        /deep/ .el-col {
            height: 100%;
        }

        .menu_left {
            height: 100%;
            border-right: 1px solid #c9c9c9;

            /deep/ .el-menu {
                border-right: 0px !important;

                /deep/ .el-menu-item-group__title {
                    padding: 0 !important;
                }

                /deep/ .el-menu-item {
                    position: relative;
                    padding-left: 80px !important;
                    @include flex-row();

                    .chapter_num {
                        padding-left: 15px;
                    }
                }

                /deep/ .el-submenu__title {
                    // background: #f5f5f5;
                    // border-bottom:1px solid #c9c9c9;
                    border-top: 1px solid #c9c9c9;
                }

                .chapter_volume {
                    height: 56px;
                    border: 1px solid transparent;
                    &.chapter_over_class{
                        border: 1px solid red;
                    }
                    span:nth-child(2) {
                        padding-left: 15px;
                    }
                }
            }
        }
    }

    .edictor_out {
        width: 100%;
        height: calc(100% - 130px);
        overflow-y: auto;
        background: #fafcff;

        #edictor {
            margin: 14px 100px !important;
            z-index: 100 !important;
            height: 96%;
            background: #ffffff;
            border-radius: 6px;

            /deep/ p,
                // /deep/div,
                // /deep/span,
            /deep/ .placeholder {
                font-size: 18px !important;
                text-indent: 2em !important;
                letter-spacing: 1px;
                line-height: 2.1em !important;
                padding: 0 60px !important;
            }

            /deep/ .placeholder {
                padding: 0px 64px !important;
            }
        }
    }

    .title_row {
        @include flex-row();

        #works_title {
            outline: none;
            width: 100%;
            height: 80px;
            font-size: 18px;
            line-height: 60px;
            resize: none;
            padding: 9px 40px;
            color: #999999;
            border-color: #e4e4e4 !important;
            border-left: 0px;
        }

        .works_num_box {
            @include flex-row-center();
            width: 200px;
            height: 80px;
            line-height: 40px;
            border: 1px solid #e4e4e4;
            border-left: 0px;

            /deep/ .el-input {
                width: 54px;
                margin: 0 5px;

                /deep/ .el-input__inner {
                    padding: 0px !important;
                    border: 0px !important;
                    border-bottom: 1px solid #e4e4e4 !important;
                    text-align: center;
                }
            }
        }
    }

    .top_title {
        width: 100%;
        height: 50px;
        @include flex-row-right();
        padding: 10px 20px;
        background: #fafcff;

        /deep/ .el-button {
            padding: 6px 12px !important;
        }
    }
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #c9c9c9;
}

/deep/ .el-dialog__body {
    padding: 0;
}

.volume_commod {
    @include flex-row-start();

    .add_volume {
        height: 40px;
        @include flex-row();
        padding: 8px 15px;
        background: #f5f5f5;
        cursor: pointer;
    }

    .volume_menu {
        @include flex-column();
        width: 260px;
    }

    .volume_item {
        @include flex-column();
        cursor: move;

        .chapter_box {
            @include flex-row();
            font-size: 16px;
            padding: 5px 0;

            span:nth-child(1) {
                margin-right: 10px;
            }
        }

        position: relative;
        min-height: 80px;
        padding: 8px 15px;

        &.item-before:after,
        &.item-after:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 80px;
            background: #eee;
            border-radius: 5px;
        }

        &.item-before {
            padding-top: 80px;
        }

        &.item-before:after {
            top: 0;
        }

        &.item-after {
            padding-bottom: 80px;
        }

        &.item-after:after {
            bottom: 0;
        }
    }
}

.menu_right {
    padding: 40px 40px 20px;
    flex: 1;
    border-left: 1px solid #c9c9c9;

    .volume_num {
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0 20px;
        border-bottom: 1px solid #c9c9c9;
    }

    .volume_name {
        height: 300px;
        @include flex-row-center();

        span {
            margin-right: 10px;
        }

        /deep/ .el-input {
            width: 250px;
        }
    }

    .btn_arr {
        text-align: end;

        /deep/ .el-button {
            padding: 6px 12px !important;
        }
    }

    /deep/ .text_input {
        /deep/ .label {
        }
    }
}

.choose_it {
    background: #edf6ff !important;
}

.edit_box {
    @include flex-row();

    .dis_edit {
        position: relative;

        .dis_edit_arr {
            position: absolute;
            bottom: -42px;
            left: -40px;
            width: 100px;
            border-radius: 4px;
            background: #ffffff;
            overflow: hidden;
            box-shadow: 0 0 4px #bfbfbf;

            span {
                width: 100%;
                padding: 8px 10px;
                @include flex-row-center();
                cursor: pointer;

                &:hover {
                    color: #409eff;
                }
            }
        }
    }

    i {
        font-size: 22px;
        padding: 0 12px;
        cursor: pointer;

        &:hover {
            color: #409eff;
        }
    }
}

.from_dig {
    width: 100%;
    @include flex-row-center();
    padding: 30px 0 10px;

    .ruleForm {
        /deep/ .el-input {
            width: 260px;
        }
    }
}

.down_title {
    justify-content: space-between !important;

    .text_warning {
        color: #f56c6c;
    }
}

.chapter_container {
    @include flex-column();
    height: 400px;
    overflow-y: auto;
}

.main_menu_box {
    height: calc(100vh - 120px);
    overflow-y: auto;
}

.el-menu-item.is-active {
    color: #333 !important;
}

.publish-article {
    li li {
        height: auto;
        padding: 0 !important;

        .drag-item {
            position: relative;
            min-height: 50px;
            cursor: move;
            padding: 0 45px 0 75px;

            &.active {
                color: #409eff;
            }

            &.item-before:after,
            &.item-after:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                width: 100%;
                height: 50px;
                background: #eee;
                border-radius: 5px;
            }

            &.item-before {
                padding-top: 50px;
            }

            &.item-before:after {
                top: 0;
            }

            &.item-after {
                padding-bottom: 50px;
            }

            &.item-after:after {
                bottom: 0;
            }
        }
    }
}

.page_status {
    position: absolute;
    left: 20px;
    background: #eeeeee;
    line-height: 32px;
    font-size: 12px;
    padding: 0 5px;
    border-radius: 4px;
    color: #ffffff;
}

.no_examine {
    background: #ff9966;
}

.have_examine {
    background: #f56c6c;
}

.no_notic {
    width: 100%;
    height: 100%;
}

.no_notic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        padding-bottom: 15px;
    }

    /deep/ .el-button {
        padding: 8px 18px !important;
    }
}

.go_up {
    margin: 0 10px;
}

.word_num {
    border-radius: 6px;
    position: absolute;
    bottom: 28px;
    left: 10px;
    z-index: 100;
    background: #ffffff;
    padding: 5px 10px;
    max-width: 80px;
    text-align: center;
    width: 80px;
    font-size: 12px;
    color: #666666;
    box-shadow: 0 0 5px #d5d5d5;

    .num {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.right_box {
    position: relative;
}

#edictor {
    box-shadow: 0 0 5px #d5d5d5;

    /deep/ div {
        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

/deep/ .header {
    background: #fafcff;
    border-bottom: 1px solid #c9c9c9;
}
</style>
