import {request} from "./config"

let NewUrl = ''

// 写作中心
export const write_chapter = (params) => request({
    method: 'get',
    url: NewUrl + 'web/write/chapter',
    data: params
})

// 写作中心 获取章节内容
export const works_content = (params) => request({
    method: 'get',
    url: NewUrl + 'web/write/content',
    data: params
})

// 写作中心 获取分卷内容
export const chapter_manage = (params) => request({
    method: 'get',
    url: NewUrl + 'web/write/chapter_manage',
    data: params
})

// 写作中心 移动章节
export const move_chapter = (params) => request({
    method: 'get',
    url: NewUrl + 'api/writes/moveSection',
    data: params
})

// 写作中心 移动卷
export const move_vol = (params) => request({
    method: 'get',
    url: NewUrl + 'api/writes/moveChapter',
    data: params
})

// 写作中心 新增章节
export const add_section = (params) => request({
    method: 'post',
    url: NewUrl + 'web/write/add_section',
    data: params
})

// 写作中心 保存章节
export const edit_content = (params) => request({
    method: 'post',
    url: NewUrl + 'web/write/edit_content',
    data: params
})

// 写作中心 删除章节
export const del_content = (params) => request({
    method: 'post',
    url: NewUrl + 'web/write/del_content',
    data: params
})

// 写作中心 编辑/新增卷
export const edit_chapter = (params) => request({
    method: 'post',
    url: NewUrl + 'web/write/edit_chapter',
    data: params
})

// 写作中心 删除卷
export const del_chapter_it = (params) => request({
    method: 'post',
    url: NewUrl + 'web/write/del_chapter',
    data: params
})
